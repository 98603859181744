







































































import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { BlokFormNewsleter, FieldsNewsletterForm } from '../../types';
import ButtonWrapper from '@/components/utilities/buttons/ButtonWrapper';

@Component({
  components: { ButtonWrapper }
})
export default class FormNewsletter extends Vue {
  @Prop() blok!: BlokFormNewsleter;
  $refs!: { select: HTMLElement, selectInput: HTMLInputElement };
  form: FieldsNewsletterForm = {
    salutation: '',
    firstname: '',
    lastname: '',
    email: ''
  };
  selectSalutationVisible: boolean = false;
  salutationOptions: string[] = ['Frau', 'Herr', 'Divers'];
  formSent: boolean = false;
  error: string = '';
  get formValid (): boolean {
    if (!this.form.firstname) { return false; }
    if (!this.form.lastname) { return false; }
    if (!this.form.email) {
      return false;
    } else {
      const regexMail: RegExp = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (!regexMail.test(this.form.email)) { return false; }
    }
    return true;
  }
  signUp (): void {
    if (!this.formValid) {
      this.error = 'Fülle bitte alle Formularfelder aus.';
    } else {
      this.mailChimpFunction();
    }
  }
  async mailChimpFunction () {
    await this.$axios.post('https://echt.ch/.netlify/functions/news-better-form', {
      email: this.form.email,
      firstname: this.form.firstname,
      lastname: this.form.lastname,
      salutation: this.form.salutation
    })
      .then((res: any) => {
        this.formSent = true;
      })
      .catch((e: any) => {
        this.error = 'Es ist ein interner Fehler aufgetreten, versuchen Sie es später erneut.';
      });
  }
  showSelectList (isVisible: boolean): void {
    this.selectSalutationVisible = isVisible;
  }
  setOption (option: string): void {
    this.form.salutation = option;
    this.showSelectList(false);
  }
  mounted () {
    if (process.browser) {
      this.$refs.select.style.width = `${this.$refs.selectInput.offsetWidth}px`;
      this.$refs.select.style.top = `${this.$refs.selectInput.offsetHeight}px`;
    }
  }
}
